export const SendFormData = (formData) => {
	return async function(dispatch, getState){
		var SEND_NEW_EMAIL = `mutation SendNewEmail($formData: FormDataInput){
			sendNewEmail(formData: $formData){
				id
			}}`

		var {serverUrl} = getState().app,
		query = JSON.stringify({query: SEND_NEW_EMAIL, variables: { formData }}), 
		response = await fetch(`${serverUrl}/graphql`, {headers: {'content-type': 'application/json'}, method: 'POST', body: query});

		console.log('formData', formData)
		await response.json().then(data => {
			if(data.errors){console.log('sendNewEmail - An error occured ', data.errors)}
			else {
				var {sendNewEmail} = data[Object.keys(data)[0]],
				{id} = sendNewEmail || {}

				if(id === "success"){
					dispatch({ type: "UPDATE_EMAIL_ENQUIRY_SENT", emailEnquirySent: true })
				}

			}
		});
	}
}

export const ToggleEmailEnquirySent = (emailEnquirySent) => {
	return async function(dispatch, getState){

		dispatch({ type: "UPDATE_EMAIL_ENQUIRY_SENT", emailEnquirySent })

	}
}


// Slack Notifications

const SEND_SLACK_NOTIFICATION = `mutation CreateSlackMessage($newSlackMessage: SlackMessageInput){createSlackMessage(newSlackMessage: $newSlackMessage){id }}`;

export const SendSlackUsageNotification = (channel, messageText, pageName, deviceType) => {
	return async function(dispatch, getState) {
		ExternalSendSlackUsageNotification(dispatch, getState, channel, messageText, pageName, deviceType)
	}
}

export async function ExternalSendSlackUsageNotification(dispatch, getState, channel, messageText, pageName, deviceType){
	var dateTime = new Date(), newSlackMessage = {messageText: `${messageText} - ${dateTime.toString().slice(0,28)}`, signedInUserId: null, environment: process.env.NODE_ENV, channel, pageName, deviceType},
	query = JSON.stringify({query: SEND_SLACK_NOTIFICATION ,variables: { newSlackMessage }}),
	response = await fetch(`${getState().app.serverUrl}/graphql`, {headers: {'content-type': 'application/json'}, method: 'POST', body: query});

	await response.json().then(data => {if(data.errors){console.log('an error occured ', data.errors)}});
}


export const IncCacheBusterCount = () => {
	return async function(dispatch, getState) {
		var {cacheBusterCount} = getState().app || {}

		dispatch({ type: "UPDATE_CACHEBUSTER_COUNT", cacheBusterCount: cacheBusterCount ? cacheBusterCount+1 : 1 })
	}
}









