// import gql from 'graphql-tag'


// Cms

export const SEED_PRACTICE_AREA_COPY = 
	`mutation SeedCmsCopy($projectId: String, $title: String, $altDb: String){
		seedCmsCopy(projectId: $projectId, title: $title, altDb: $altDb){
			id
		}}`,

UPDATE_CMS_COPY = 
	`mutation UpdateCmsCopy($copyId: String, $inputField: String, $inputValue: String, $altDb: String){
		updateCmsCopy(copyId: $copyId, inputField: $inputField, inputValue: $inputValue, altDb: $altDb){
			id
		}}`

export const LOAD_CMS_COPIES = 
	`query LoadCmsCopies($altDb: String){
		getCmsCopies(altDb: $altDb){
			id title markdown
		}}`;