import { combineReducers } from 'redux'

import app from './app'
import cms from './cms'


const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_REDUX_ON_LOGOUT') {state = undefined}
  return appReducer(state, action)
}

const appReducer = combineReducers({
	app,
	cms
})

export default rootReducer;