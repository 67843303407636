import React from 'react';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverMismatch = (versionA, versionB) => {
  const versionsA = versionA.split(), versionsB = versionB.split()
  return versionsA[0] === versionsB[0]
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: (actions, cacheBusterCount) => {
        console.log('Clearing cache and hard reloading...', actions)

        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          console.log('caches are present', caches)
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        localStorage.clear();

        var channel = 'random', dateTime = new Date(),
        messageText = `:recycle: [CacheBuster] - Cache busted for Davey by CacheBuster - ${String(dateTime).slice(0,21)}`;

        actions.SendSlackUsageNotification(channel, messageText)

        if(cacheBusterCount < 3){
          console.log('cacheBusterCount', cacheBusterCount)

          actions.IncCacheBusterCount()

          setTimeout(() => window.location.reload(true), 3000);
        }
      }
    };
  }

  componentDidMount() {
    fetch('meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        console.log('meta', meta)

        const shouldForceRefresh = semverMismatch(latestVersion, currentVersion);

        if (meta && !shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion} / ${currentVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion} / ${currentVersion}. No cache refresh needed.`);

          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;