import './Davey/Style/style.scss'

import {useEffect, Suspense, lazy} from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import * as actions from './Redux/actions'
import * as fn from './Util/GeneralFunctions'

import CacheBuster from './Util/CacheBuster'


const Admin = lazy(() => import('./Davey/Pages/Admin')),
Landing = lazy(() => import('./Davey/Pages/Landing')),
Profile = lazy(() => import('./Davey/Pages/Profile')),
Testimonials = lazy(() => import('./Davey/Pages/Testimonials')),
PracticeAreas = lazy(() => import('./Davey/Pages/PracticeAreas')),
Contact = lazy(() => import('./Davey/Pages/Contact')),
Client = lazy(() => import('./Davey/Pages/ClientInformation'))


const App = ({actions, currentUser, awsPrefix, cms, lastRefreshDate, cacheBusterCount }) => {

  var isMobile = window.screen.width < 650,
  {cmsCopies} = cms || {}

  useEffect(() => {!cmsCopies && actions.LoadCmsCopies()})

  useEffect(() => {
    fn.CheckIfDataRefreshNeeded(lastRefreshDate) && 
    lastRefreshDate && 
    actions.LoadCmsCopies()}, [actions, lastRefreshDate])

  if (!window.google) {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `${process.env.REACT_APP_PLACES_SCRIPT}`;
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }


  function Routes() {
    return (
      <Switch>

        {/* PracticeAreas */}
        <Route exact path="/arbitration" render={(props) => 
          <PracticeAreas 
            sectionName={'Arbitration'}
            isMobile={isMobile}/>}/>

        <Route exact path="/practice-areas" render={(props) => 
          <PracticeAreas 
            sectionName={'practice-areas'}
            isMobile={isMobile}/>}/>

        <Route exact path="/civil-proceedings" render={(props) => 
          <PracticeAreas 
            sectionName={'Civil Proceedings'}
            isMobile={isMobile}/>}/>

        <Route exact path="/mediation" render={(props) => 
          <PracticeAreas 
            sectionName={'Mediation'}
            isMobile={isMobile}/>}/>

        <Route exact path="/employment" render={(props) => 
          <PracticeAreas 
            sectionName={'Employment'}
            isMobile={isMobile}/>}/>

        <Route exact path="/criminal-proceedings" render={(props) => 
          <PracticeAreas 
            sectionName={'Criminal Proceedings'}
            isMobile={isMobile}/>}/>

        <Route exact path="/criminal-appeals" render={(props) => 
          <PracticeAreas 
            sectionName={'Criminal Appeals'}
            isMobile={isMobile}/>}/>

        <Route exact path="/health-and-safety" render={(props) => 
          <PracticeAreas 
            sectionName={'Health and Safety'}
            isMobile={isMobile}/>}/>


      




        {/* Profile */}

        <Route exact path="/profile/:id" render={(props) => 
          <Profile 
            isMobile={isMobile}/>}/>

        <Route exact path="/profile" render={(props) => 
          <Profile 
            isMobile={isMobile}/>}/>

        


        {/* Contact */}

        <Route exact path="/contact" render={(props) => 
          <Contact 
            isMobile={isMobile}/>}/>

        {/* Client Information */}

        <Route exact path="/client" render={(props) => 
          <Client 
            isMobile={isMobile}/>}/>


        {/* Testimonials */}

        <Route exact path="/testimonials" render={(props) => 
          <Testimonials 
            isMobile={isMobile}/>}/>


        {/* Admin */}

        <Route exact path="/admin" render={(props) => 
          <Admin 
            isMobile={isMobile}/>}/>




        {/* Landing */}

        <Route exact path="/" render={(props) => 
          <Landing 
            isMobile={isMobile}/>}/>

        <Route render={(props) => 
          <Landing 
            isMobile={isMobile}/>}/>


      </Switch>
    );
  }

  var toCacheBustOrNotToCacheBust

  if (process.env.REACT_APP_ENV_STATUS !== "dev"){
    toCacheBustOrNotToCacheBust = 
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {refreshCacheAndReload(actions, cacheBusterCount)}
          return (
            <div className="App">
                <Suspense fallback={<div/>}>
                  {Routes()}
                </Suspense>
            </div>
          )
      }}
    </CacheBuster>
  } else {
      toCacheBustOrNotToCacheBust = 
        <div className="App">
          <Suspense fallback={<div/>}>
              {Routes()}
          </Suspense>
        </div>
  }

  return toCacheBustOrNotToCacheBust
}


const mapStateToProps = (state, ownProps) => ({
  cms: state.cms,
  lastRefreshDate: state.cms.lastRefreshDate,
  cacheBusterCount: state.app.cacheBusterCount
})

function mapDispatchToProps(dispatch) {return {actions: bindActionCreators({ ...actions }, dispatch)}}

export default connect(mapStateToProps, mapDispatchToProps)(App);
