const initialState = {}

const app = (state = initialState, action) => {
	switch (action.type){

		case 'SEED_CMS_COPY': {
			let {cmsCopy} = action;

			return {
				...state,
				cmsCopies: 
					state.cmsCopies ? [...state.cmsCopies, cmsCopy] : [cmsCopy]
				
			}
		}


		case 'UPDATE_CMS_COPY': {
			let {copyIndex, inputField, inputValue} = action

			return {
				...state,
				cmsCopies: [
					...state.cmsCopies.slice(0,copyIndex),
					{
						...state.cmsCopies[copyIndex],
						[inputField]: inputValue
					},
					...state.cmsCopies.slice(copyIndex+1)
				]
			}
		}


		// Load in

		case 'LOAD_CMS_COPIES': {
			let {cmsCopies} = action
			return {
				...state,
				cmsCopies
			}
		}

		case 'SET_LAST_REFRESH_DATE': {
			let {lastRefreshDate} = action
			return{
				...state,
				lastRefreshDate
			}
		}

		default: return state;
	}
}

export default app;