import * as qy from '../../Util/GeneralQueries'
import debounce from 'lodash/debounce'

// import * as fn from '../../Util/GeneralFunctions'


export const SeedPracticeAreaCopy = (practiceArea) => {
	return async function(dispatch, getState){

		var {cmsCopies} = getState().cms || {}

		console.log('SeedPracticeAreaCopy', practiceArea, cmsCopies)
		
		if(cmsCopies){
			SeedCopyInDb(dispatch, getState, practiceArea)
		}
	}
}


async function SeedCopyInDb(dispatch, getState, practiceArea){

	var {projectId} = getState().app || {},
	query = JSON.stringify({query: qy.SEED_PRACTICE_AREA_COPY, variables: { projectId, title: practiceArea, altDb: 'davey' }}), response = await fetch(`${getState().app.serverUrl}/graphql`, {headers: {'content-type': 'application/json'}, method: 'POST', body: query});

	await response.json().then(data => {
		if(data.errors){console.log('an error occured ', data.errors)}
		else {
			var {seedCmsCopy} = data[Object.keys(data)[0]];

			dispatch({type: "SEED_CMS_COPY", cmsCopy: {id: seedCmsCopy.id, title: practiceArea, markdown: ''}})
		}
	});

}



export const UpdatePracticeAreaCopy = (copyId, inputField, inputValue) => {
	return async function(dispatch, getState){

		var {cmsCopies} = getState().cms || {},
		copyIndex = cmsCopies && cmsCopies.findIndex(x => x.id === copyId)

		dispatch({type: "UPDATE_CMS_COPY", copyIndex, inputField, inputValue})


		CopyDebouncer(dispatch, getState, copyId, inputField, inputValue)
	}
}

var CopyDebouncer = debounce((dispatch, getState, copyId, inputField, inputValue) => UpdateCopy(dispatch, getState, copyId, inputField, inputValue), 1000);


async function UpdateCopy(dispatch, getState, copyId, inputField, inputValue){
	var {altDb} = getState().app || {},
	query = JSON.stringify({query: qy.UPDATE_CMS_COPY, variables: { copyId, inputField, inputValue, altDb }}), response = await fetch(`${getState().app.serverUrl}/graphql`, {headers: {'content-type': 'application/json'}, method: 'POST', body: query});

	await response.json().then(data => {
		if(data.errors){console.log('an error occured ', data.errors)}
	});

}




// Load CmsCopy


export function LoadCmsCopies(){
	return async function(dispatch, getState){
		var {altDb} = getState().app || {},
		query = JSON.stringify({query: qy.LOAD_CMS_COPIES, variables: { altDb }}), response = await fetch(`${getState().app.serverUrl}/graphql`, {headers: {'content-type': 'application/json'}, method: 'POST', body: query});

		await response.json().then(data => {
			if(data.errors){console.log('an error occured ', data.errors)}
			else {
				var {getCmsCopies} = data[Object.keys(data)[0]],
				lastRefreshDate = new Date().getTime()

				console.log('LoadCmsCopies')

				dispatch({type: "LOAD_CMS_COPIES", cmsCopies: getCmsCopies})

				dispatch({type: "SET_LAST_REFRESH_DATE", lastRefreshDate})

			}
		});

	}
}










