

export function FormatItemUrl(inputString){
	return inputString.replace(/\s+/g, '-').toLowerCase();
}

export function CapitaliseString(string){return string ? string.charAt(0).toUpperCase() + string.substring(1) : ""}



export function CheckCurrentUserAdmin(currenUser){
	return false
}


export function CheckIfDataRefreshNeeded(lastRefreshDate, timeToWait){
	var currentTime = new Date().getTime(),
	timeDelta = currentTime - lastRefreshDate

	var returnValue = !lastRefreshDate || (timeDelta > (timeToWait || 30000))

	console.log('refreshneeded', returnValue, lastRefreshDate)

	return returnValue
}

export const CheckEmailValid = (emailAddress) => {
	if (/^\w+([.-.+._.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)){return true}; return false;
}
