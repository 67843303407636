import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';
import { loadState } from '../Util/SaveAndLoadState';


var persistedState = loadState();

export const middlewares = [ReduxThunk];

let  createStoreWithMiddleware;
if (process.env.REACT_APP_ENV_STATUS === "dev" && window.__REDUX_DEVTOOLS_EXTENSION__) {
	createStoreWithMiddleware = compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())(createStore);
} else {
	createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(createStore);
}

export default createStoreWithMiddleware(rootReducer, persistedState)
