import React from 'react'
import {render} from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { throttle } from 'lodash'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from "@auth0/auth0-react";

import store from './Redux/configureStore'

import { saveState } from './Util/SaveAndLoadState'

var clientUri;

console.log('process.env.REACT_APP_ENV_STATUS', process.env.REACT_APP_ENV_STATUS)

if (process.env.REACT_APP_ENV_STATUS === "dev") {
    clientUri = "http://localhost:3001/graphql"
} else {
    clientUri = "https://outer201.herokuapp.com/graphql"; 
    if (window.location.protocol !== 'https:') {
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
    }
}

const client = new ApolloClient({cache: new InMemoryCache({addTypename: true}),uri: clientUri});

store.subscribe(throttle(() => {
  saveState({
    app: store.getState().app,
    cms: store.getState().cms
  })}, 1000))

console.log('origin', window.location.origin)

const Root = ({ store }) => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Auth0Provider
      domain="davey.au.auth0.com"
      clientId="t9uSHXX5XmqvIoyofDo4RYedTCr0Ikby"
      redirectUri={window.location.origin}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </Provider>
  </ApolloProvider>
);

render(<Root store={store}/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
