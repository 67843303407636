const initialState = {
	serverUrl: process.env.REACT_APP_ENV_STATUS === "dev" ? "http://localhost:3001" : "https://outer201.herokuapp.com",
	awsPrefix: 'https://res302.s3-us-west-2.amazonaws.com/davey/',
	projectId: '1',
	altDb: 'davey'
}

const app = (state = initialState, action) => {
	switch (action.type){

		case 'UPDATE_EMAIL_ENQUIRY_SENT': {
			let {emailEnquirySent} = action
			return {
				...state,
				emailEnquirySent
			}
		}

		case 'UPDATE_CACHEBUSTER_COUNT':  {
			let {cacheBusterCount} = action
			return {
				...state,
				cacheBusterCount
			}
		}

		default: return state;
	}
}

export default app;